import Swiper, { Autoplay} from 'swiper';
Swiper.use([Autoplay]);
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

export default class WbCustomSlider {
    constructor() {
        this.sliders = document.querySelectorAll('.wb-custom-slider');
        this.initialize();
        this.bindEvents();
    }

    initialize = () => {

        this.recalculateSliderHeight();

        this.sliders.forEach((slider) => {

            let slides = slider.querySelectorAll('.swiper-slide');
            let titles = [];
            slides.forEach((slide) => {
                titles.push(slide.dataset.title);
            });

            let options = {
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    type: 'custom',
                    renderCustom: function(swiper, current, total) {
                        let text = "<ul>";
                        for (let i = 1; i <= total; i++) {

                            if (current === i) {
                                text += `<li class="swiper-pagination-bullet pagination-item active"><div class="pagination-item__title text-md">${titles[i - 1]}</div></li>`;
                            } else {
                                text += `<li class="swiper-pagination-bullet pagination-item"><div class="pagination-item__title text-md">${titles[i - 1]}</div></li>`;
                            }
                        }
                        text += "</ul>";
                        return text;
                    }
                },
            };

            if (slides.length > 1) {
                options.loop = true;
                options.autoplay = {
                    delay: 4000
                };
            }

            new Swiper(slider, options);
        });
    };

    bindEvents = () => {
        let videos = document.querySelectorAll('.js-video');
        videos.forEach((video) => {
            video.addEventListener('mouseenter', () => {
                video.setAttribute('controls', '');
            });
            video.addEventListener('mouseleave', () => {
                video.removeAttribute('controls');
            });
        });

        window.addEventListener('load', () => {
            this.recalculateSliderHeight();
        });
        window.addEventListener('orientationchange', () => {
            this.recalculateSliderHeight();
        });
        window.addEventListener('resize', () => {
           this.recalculateSliderHeight();
        });
    };

    recalculateSliderHeight = () => {
        let slides = document.querySelectorAll('.js-slide');
        slides.forEach(slide => {
            //slide.setAttribute('min-height', window.innerHeight - 106);
            let slideHeight = window.innerHeight - 106;
            slide.style.height = `${slideHeight}px`;
            slide.style.minHeight = `${slideHeight}px`;

            if (slide.classList.contains('news-slide_center-aligned') || slide.classList.contains('news-slide_right-aligned')) {
                let cssRules = `.news-slide__content::before { border-width: 0 400px ${slideHeight}px 0 !important;}`;
                let head = document.head || document.getElementsByTagName('head')[0];
                let style = document.createElement('style');
                head.appendChild(style);
                style.appendChild(document.createTextNode(cssRules));
            }

            if (slide.classList.contains('news-slide_left-aligned')) {
                let cssRules = `.news-slide__content::before { border-width: ${slideHeight}px 400px 0 0 !important;}`;
                let head = document.head || document.getElementsByTagName('head')[0];
                let style = document.createElement('style');
                head.appendChild(style);
                style.appendChild(document.createTextNode(cssRules));
            }
        });
        //console.log('height recalculated');
    };
}
