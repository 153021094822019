export default class HomePage {
    constructor() {
        this.page = document.querySelector('.page-template-home');
        if (this.page) {
            this.initialize();
            this.bindEvents();
        }
    }

    initialize = () => {

    };

    bindEvents = () => {

    };
}