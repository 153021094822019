export default class NewsSearchBlock {
    constructor() {
        this.topicLinks = document.querySelectorAll('.js-topic-link');
        this.initialize();
    }

    initialize = () => {
        this.topicLinks.forEach((link) => {
            if (window.location.href === link.getAttribute('href')) {
                link.classList.add('active');
            }
        });
    };
}
