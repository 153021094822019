export default class OtherSalesPage {
    constructor() {
        this.page = document.querySelector('.other-sales-page');
        if (this.page) {
            this.initialize();
        }
    }

    initialize = () => {
        let infoBlock = document.querySelector('.info');
        let rect = infoBlock.getBoundingClientRect();
        window.scrollTo({
            top: rect.top - 100,
            left: 0,
            behavior: 'smooth'
        });
    };
}