export default class SubscriptionForm {
    constructor() {
        this.forms = document.querySelectorAll('.express-form_subscription-form');
        this.initialize();
    }

    initialize = () => {
        this.forms.forEach((form) => {
            this.input = form.querySelector('input[type=email]');
            this.input.placeholder = 'Enter your email';
        });
    };
}
