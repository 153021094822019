export default class SalePage {
    constructor() {
        this.page = document.querySelector('.page-template-sale');
        if (this.page) {
            this.initialize();
            this.bindEvents();
        }
    }

    initialize = () => {
        this.lotFilterForm = document.querySelector('.js-lot-filter-form');
        this.lotFilters = document.querySelectorAll('.js-lot-filter');
    };

    bindEvents = () => {
        this.lotFilters.forEach((filter) => {
            filter.addEventListener('change', (e) => {
                let title = e.target.title;
                let el = this.lotFilterForm.querySelector(`input[name="${title}"]`);
                el.value = e.target.value;
                this.lotFilterForm.submit();
            });
        });
    };

    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}