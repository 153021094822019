export default class BookingForm {
    constructor() {
        this.form = document.querySelector('.js-booking-form');
        if (this.form) {
            this.initialize();
        }
    }

    initialize = () => {
        let ownerEmail = this.form.querySelector('.express-form__wrapper > :nth-child(25) input');
        let ownerMobile = this.form.querySelector('.express-form__wrapper > :nth-child(26) input');
        let ownerPhone = this.form.querySelector('.express-form__wrapper > :nth-child(27) input');
        ownerEmail.placeholder = 'Email';
        ownerMobile.placeholder = 'Mobile';
        ownerPhone.placeholder = 'Home/work phone number';

        let params = new URLSearchParams(window.location.search);
        if (params.has('stallion')) {
            let stallion = params.get('stallion');
            let select = document.querySelector('select').getElementsByTagName('option');
            for (let i = 0; i < select.length; i++) {
                if (select[i].text === stallion) select[i].selected = true;
            }
        }
    };
}
