export default class WbAccordion {
    constructor() {
        this.accordions = document.querySelectorAll('.wb-accordion');
        this.bindEvents();
    }

    bindEvents = () => {
        this.accordions.forEach((accordion) => {
            let toggle = accordion.querySelector('.js-accordion-toggle');
            let content = accordion.querySelector('.js-accordion-content');

            toggle.addEventListener('click', (e) => {
                toggle.classList.toggle('activated');
                let contentClassName = this.getElementClassName(content);
                let contentModifierName = `${contentClassName}_expanded`;
                content.classList.toggle(contentModifierName);
            });
        });
    };
    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}