import imagesLoaded from 'imagesloaded';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default class WbImageGallery {
    constructor() {
        this.initialize();
    }

    initialize = () => {
        this.resizeAllGridItems();
        window.addEventListener("resize", this.resizeAllGridItems);

        let allItems = document.getElementsByClassName('js-grid-item');
        for (let x = 0; x < allItems.length; x++) {
            imagesLoaded(allItems[x], this.resizeInstance);
        }
        this.initPhotoSwipeFromDOM('.js-grid');
    };

    resizeGridItem = (item) => {
        let grid = document.querySelector('.js-grid');
        let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
        let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
        let contentWrap = item.querySelector('.js-grid-item-content');
        let image = contentWrap.querySelector('img');
        let rowSpan = Math.ceil((contentWrap.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
        item.style.gridRowEnd = "span " + rowSpan;
        image.style.minHeight = item.offsetHeight + "px";
    };

    resizeAllGridItems = () => {
        let allItems = document.getElementsByClassName('js-grid-item');
        for (let x = 0; x < allItems.length; x++) {
            this.resizeGridItem(allItems[x]);
        }
    };

    resizeInstance = (instance) => {
        let item = instance.elements[0];
        this.resizeGridItem(item);
    };

    initPhotoSwipeFromDOMOld = (gallerySelector) => {

        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        let parseThumbnailElements = function (el) {
            let thumbElements = el.childNodes,
                numNodes = thumbElements.length,
                items = [],
                galleryItem,
                pictureEl,
                size,
                item;

            for (let i = 0; i < numNodes; i++) {

                galleryItem = thumbElements[i]; // <figure> element

                // include only element nodes
                if (galleryItem.nodeType !== 1) {
                    continue;
                }

                let pictureEl = galleryItem.querySelector('.js-image');
                let imgEl = pictureEl.querySelector('img');

                size = pictureEl.getAttribute('data-size').split('x');

                // create slide object
                item = {
                    src: imgEl.getAttribute('src'),
                    title: pictureEl.getAttribute('data-caption'),
                    w: parseInt(size[0], 10),
                    h: parseInt(size[1], 10)
                };

                if (pictureEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = pictureEl.children[0].getAttribute('src');
                }

                item.el = galleryItem; // save link to element for getThumbBoundsFn
                items.push(item);
            }

            return items;
        };

        // find nearest parent element
        let closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        // triggers when user clicks on thumbnail
        let onThumbnailsClick = function (e) {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : e.returnValue = false;

            let eTarget = e.target || e.srcElement;

            // find root element of slide
            let clickedListItem = closest(eTarget, function (el) {
                //return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
                return (el.classList.contains('js-grid-item'));
            });

            if (!clickedListItem) {
                return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            let clickedGallery = clickedListItem.parentNode,
                childNodes = clickedListItem.parentNode.childNodes,
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index;

            for (let i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue;
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }

            if (index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe(index, clickedGallery);
            }
            return false;
        };

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        let photoswipeParseHash = function () {
            let hash = window.location.hash.substring(1),
                params = {};

            if (hash.length < 5) {
                return params;
            }

            let vars = hash.split('&');
            for (let i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue;
                }
                let pair = vars[i].split('=');
                if (pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            return params;
        };

        let openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
            let pswpElement = document.querySelectorAll('.pswp')[0],
                gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement);

            // define options (if needed)
            options = {

                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                getThumbBoundsFn: function (index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    let thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                        rect = thumbnail.getBoundingClientRect();

                    return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
                }

            };

            // PhotoSwipe opened from URL
            if (fromURL) {
                if (options.galleryPIDs) {
                    // parse real index when custom PIDs are used
                    // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                    for (let j = 0; j < items.length; j++) {
                        if (items[j].pid == index) {
                            options.index = j;
                            break;
                        }
                    }
                } else {
                    // in URL indexes start from 1
                    options.index = parseInt(index, 10) - 1;
                }
            } else {
                options.index = parseInt(index, 10);
            }

            // exit if index not found
            if (isNaN(options.index)) {
                return;
            }

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        };

        // loop through all gallery elements and bind events
        let galleryElements = document.querySelectorAll(gallerySelector);

        for (let i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        let hashData = photoswipeParseHash();
        if (hashData.pid && hashData.gid) {
            openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
        }
    };

    initPhotoSwipeFromDOM = (gallerySelector) => {

        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        let parseThumbnailElements = function (el) {
            let thumbElements = el.childNodes,
                numNodes = thumbElements.length,
                items = [],
                galleryItem,
                pictureEl,
                size,
                item;

            for (let i = 0; i < numNodes; i++) {

                galleryItem = thumbElements[i]; // <figure> element

                // include only element nodes
                if (galleryItem.nodeType !== 1) {
                    continue;
                }

                let pictureEl = galleryItem.querySelector('.js-image');
                let imgEl = pictureEl.querySelector('img');

                size = pictureEl.getAttribute('data-size').split('x');

                // create slide object
                item = {
                    src: imgEl.getAttribute('src'),
                    title: pictureEl.getAttribute('data-caption'),
                    w: parseInt(size[0], 10),
                    h: parseInt(size[1], 10)
                };

                if (pictureEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = pictureEl.children[0].getAttribute('src');
                }

                item.el = galleryItem; // save link to element for getThumbBoundsFn
                items.push(item);
            }

            return items;
        };

        // find nearest parent element
        let closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        // triggers when user clicks on thumbnail
        let onThumbnailsClick = function (e) {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : e.returnValue = false;

            let eTarget = e.target || e.srcElement;

            // find root element of slide
            let clickedListItem = closest(eTarget, function (el) {
                //return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
                return (el.classList.contains('js-grid-item'));
            });

            if (!clickedListItem) {
                return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            let clickedGallery = clickedListItem.parentNode,
                childNodes = clickedListItem.parentNode.childNodes,
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index;

            for (let i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue;
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }

            if (index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe(index, clickedGallery);
            }
            return false;
        };

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        let photoswipeParseHash = function () {
            let hash = window.location.hash.substring(1),
                params = {};

            if (hash.length < 5) {
                return params;
            }

            let vars = hash.split('&');
            for (let i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue;
                }
                let pair = vars[i].split('=');
                if (pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            return params;
        };

        let openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
            let pswpElement = document.querySelectorAll('.pswp')[0],
                gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement);

            // define options (if needed)
            options = {

                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                getThumbBoundsFn: function (index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    let thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                        rect = thumbnail.getBoundingClientRect();

                    return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
                }

            };

            // PhotoSwipe opened from URL
            if (fromURL) {
                if (options.galleryPIDs) {
                    // parse real index when custom PIDs are used
                    // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                    for (let j = 0; j < items.length; j++) {
                        if (items[j].pid == index) {
                            options.index = j;
                            break;
                        }
                    }
                } else {
                    // in URL indexes start from 1
                    options.index = parseInt(index, 10) - 1;
                }
            } else {
                options.index = parseInt(index, 10);
            }

            // exit if index not found
            if (isNaN(options.index)) {
                return;
            }

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        };

        // loop through all gallery elements and bind events
        let galleryElements = document.querySelectorAll(gallerySelector);

        for (let i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        let hashData = photoswipeParseHash();
        if (hashData.pid && hashData.gid) {
            openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
        }
    };

}