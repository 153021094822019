export default class SearchBlock {
    constructor() {
        this.body = document.querySelector('body');
        this.ccm = document.querySelector('.ccm-page');
        this.search = document.querySelectorAll('.js-search-form');
        if (this.search.length > 0) {
            this.initialize();
        }
    }

    initialize = () => {
        this.search.forEach((form) => {
            let loupe = form.querySelector('.loupe');
            loupe.addEventListener('click', (event) => {
                event.preventDefault();
                document.querySelector('.js-search-form').submit();
            });
        });

        if (!this.ccm.classList.contains('is-edit-mode')) {
            this.body.addEventListener('keyup', (event) => {
                if (event.code === 'Enter') {
                    event.preventDefault();
                    document.querySelector('.js-search-form').submit();
                }
            });
        }
    };
}
