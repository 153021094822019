import Swiper from 'swiper';
import SwiperCore, { Navigation, Thumbs, Pagination} from 'swiper';
SwiperCore.use([Navigation, Pagination, Thumbs]);

export default class LotPage {
    constructor() {
        this.page = document.querySelector('.page-template-lot');
       if (this.page) {
           this.images = this.page.querySelector('.js-lot-images');
           this.initialize();
           this.bindEvents();
       }
    }

    initialize = () => {
      if (this.images) {

          let swiper = new Swiper(".thumbs-swiper", {
              spaceBetween: 10,
              slidesPerView: 4,
              freeMode: true,
              watchSlidesProgress: true,
          });

          let swiper2 = new Swiper(".main-swiper", {
              spaceBetween: 10,
              navigation: {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
              },
              thumbs: {
                  swiper: swiper,
              },
          });
      }
    };

    bindEvents = () => {

    };

    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}