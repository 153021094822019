export default class OurTeamForm {
    constructor() {
        this.body = document.querySelector('body');
        this.ccm = document.querySelector('.ccm-page');
        this.block = document.querySelector('.our-team');
        if (this.block) {
            this.form = document.querySelector('.js-team-form');
            this.select = this.form.querySelector('select');
            this.bindEvents();
        }
    }

    bindEvents = () => {
        if (!this.ccm.classList.contains('is-edit-mode')) {
            this.body.addEventListener('keyup', (event) => {
                if (event.code === 'Enter')
                {
                    event.preventDefault();
                    this.form.submit();
                }
            });
        }

        this.select.addEventListener('change', () => {
            this.form.submit();
        });
    };
}