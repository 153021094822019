export default class HonourRollTable {
    constructor() {
        this.tables = document.querySelectorAll('.honour-roll-table');
        this.bindEvents();
    }

    bindEvents = () => {
        this.tables.forEach((table) => {
            let toggle = table.querySelector('.js-honour-roll-table-toggle');
            let content = table.querySelector('.js-honour-roll-table-content');

            toggle.addEventListener('click', (e) => {
                e.preventDefault();
                toggle.classList.toggle('activated');
                let contentClassName = this.getElementClassName(content);
                let contentModifierName = `${contentClassName}_expanded`;
                content.classList.toggle(contentModifierName);
            });
        });
    };
    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}