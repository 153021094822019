import Cookies from 'js-cookie'

export default class CtaPopup {
    constructor() {
        this.ctaPopup = document.querySelector('.js-cta-popup');
        if (this.ctaPopup) {
            this.ctaPopupClose = this.ctaPopup.querySelector('.js-cta-popup-close');
            this.initialize();
            this.bindEvents();
        }
    }

    initialize = () => {
        let ctaPopupCookie = Cookies.get('showCtaPopup');
        if (!ctaPopupCookie) {
            this.ctaPopup.classList.add('active');
            Cookies.set('showCtaPopup', 'true', {
                'domain': 'westburystud.com',
                'expires': 1,
            })
        }
    }

    bindEvents = () => {
        this.ctaPopupClose.addEventListener('click', () => {
            this.ctaPopup.classList.remove('active');
        });
    };
}
