import Swiper from 'swiper';
import SwiperCore, {Navigation, Pagination} from 'swiper/core';

SwiperCore.use([Navigation, Pagination]);


export default class GavelhouseListings {
    constructor() {
        this.ccm = document.querySelector('.ccm-page');
        if (!this.ccm.classList.contains('is-edit-mode')) {
            this.initialize();
        }
    }

    initialize = () => {
        new Swiper('.swiper', {
            spaceBetween: 30,
            loop: true,
            observer: true,
            observeParents: true,
            slidesPerView: 1,
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true,
            // },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            debugger: true
        });
    };
}
