import JsTabs from 'js-tabs';
import magnificPopup from 'magnific-popup';

export default class VimeoVideoGallery {
    constructor() {
        this.body = document.querySelector('body');
        this.ccm = document.querySelector('.ccm-page');
        this.galleries = document.querySelectorAll('.vimeo-video-gallery');
        if (this.galleries.length > 0) {
            this.initialize();
        }
    }

    initialize = () => {
        this.galleries.forEach((gallery) => {
            let galleryTabs = new JsTabs({
                elm: gallery,
                shouldScrollTabIntoView: false
            });
            galleryTabs.init();
        });

        $('.video-popup').magnificPopup({
            disableOn: 0,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    };
}