import GreenAudioPlayer from 'green-audio-player';

export default class AudioPlayer {
    constructor() {
        this.ccm = document.querySelector('.ccm-page');
        this.players = document.querySelectorAll('.js-audio-player');
        if (!this.ccm.classList.contains('is-edit-mode')) {
            this.initialize()
        }
    }

    initialize = () => {
        // GreenAudioPlayer.init({
        //     selector: '.js-audio-player',
        //     stopOthersOnPlay: true
        // });
        this.players.forEach((player) => {
            if (player.querySelector('audio')) {
                new GreenAudioPlayer(player, {
                    stopOthersOnPlay: true
                });
            }
        });
    }
}