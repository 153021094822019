import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import 'slicknav/jquery.slicknav';
import 'slicknav/scss/slicknav.scss';

export default class View {
    constructor() {
        this.desktopVersionInitialized = false;
        this.mobileVersionInitialized = false;
        this.body = document.querySelector('body');
        this.dropdownToggles = document.querySelectorAll("li[class*='has-dropdown']");
        this.mobileMenu = document.querySelector('.js-autonav-nav');
        this.mobileMenuToggle = document.querySelector('.js-autonav-toggle');
        this.bindEvents();
        this.initializeNav();
    }

    bindEvents = () => {
        window.addEventListener('load', this.initializeNav());
        window.addEventListener('orientationchange', () => {
            this.initializeNav();
        });

        let headerMenu = $('.header .header__navigation .autonav');
        $(headerMenu).slicknav({
            'label': '',
            'appendTo': '.header',
            'allowParentLinks': true,
            'removeClasses': true,
        });

        this.mobileMenuToggle.addEventListener('click', () => {

            $(headerMenu).slicknav('toggle');
            // let mobileMenuClassName = this.getElementClassName(this.mobileMenu);
            // let mobileMenuModifierName = `${mobileMenuClassName}_collapsed`;
            // this.mobileMenu.classList.toggle(mobileMenuModifierName);

            let mobileMenuToggleClassName = this.getElementClassName(this.mobileMenuToggle);
            let mobileMenuToggleModifierName = `${mobileMenuToggleClassName}_opened`;
            this.mobileMenuToggle.classList.toggle(mobileMenuToggleModifierName);

            // this.body.classList.toggle('no-overflow');
            // if (this.body.classList.contains('no-overflow')) {
            //     disableBodyScroll(this.body);
            // } else {
            //     clearAllBodyScrollLocks();
            // }
        });
        window.addEventListener('resize', () => {
            this.initializeNav();
        });
    };

    initializeNav = () => {
        this.desktopVersionInitialized = false;
        this.mobileVersionInitialized = false;

        if (window.innerWidth >= 992) {
            if (!this.desktopVersionInitialized) {
                this.dropdownToggles.forEach((toggle) => {
                    let dropdown = toggle.querySelector("ul[class*='level-1']");
                    if (dropdown) {
                        let dropdownClassName = this.getElementClassName(dropdown);
                        dropdown.style.paddingLeft = `${this.mobileMenu.offsetLeft}px`;
                        let dropdownModifierName = `${dropdownClassName}_visible`;
                        toggle.addEventListener('mouseenter', () => {
                            dropdown.classList.add(dropdownModifierName);
                        });
                        toggle.addEventListener('mouseleave', () => {
                            dropdown.classList.remove(dropdownModifierName);
                        });
                    }
                });
                this.desktopVersionInitialized = true;
                //console.log('desktop');
            }
        } else if (!this.mobileVersionInitialized) {
            this.dropdownToggles.forEach((toggle) => {
                // let dropdown = toggle.querySelector("ul[class*='level-1']");
                // if (dropdown) {
                //     let dropdownClassName = this.getElementClassName(dropdown);
                //     toggle.addEventListener('click', (e) => {
                //         if (!e.target.classList.contains('autonav__dropdown-link')) {
                //             e.preventDefault();
                //             e.stopPropagation();
                //             dropdown.classList.toggle(`${dropdownClassName}_opened`);
                //         }
                //     });
                // }
            });
            this.mobileVersionInitialized = true;
            //console.log('mobile');
        }
    };
    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}
