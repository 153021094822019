import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class AdditionalNav {
    constructor() {
        this.body = document.querySelector('body');
        this.nav = this.body.querySelector('.js-additional-nav');
        if (this.nav) {
            this.toggle = this.nav.querySelector('.additional-nav__link_selected');
            if (this.toggle) {
                this.bindEvents();
            }
        }
    }

    bindEvents = () => {
        this.toggle.addEventListener('click', (e) => {
            e.preventDefault();
            let navClassName = this.getElementClassName(this.nav);
            let navModifierName = `${navClassName}_expanded`;
            this.nav.classList.toggle(navModifierName);
            this.body.classList.toggle('no-overflow');
            if (this.body.classList.contains('no-overflow')) {
                disableBodyScroll(this.body);
            } else {
                enableBodyScroll(this.body);
            }
        });
    };
    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}
