export default class MatingAdviceForm {
    constructor() {
        this.form = document.querySelector('.js-mating-advice-form');
        if (this.form) {
            this.initialize();
        }
    }

    initialize = () => {
        let emailInput = this.form.querySelector('input[type=email]');
        emailInput.placeholder = 'Email';
        let phoneInput = this.form.querySelector('input[type=tel');
        phoneInput.placeholder = 'Mobile';
    };
}
