import Header from './js/elements/header';
import Autonav from './js/blocks/autonav/view';
import SubscriptionForm from "./js/blocks/express_form/templates/subscription_form/view";
import SearchBlock from "./js/blocks/search";
import GavelhouseListings from "./js/blocks/gavelhouse-listings";
import OurTeamForm from "./js/blocks/express_entry_list/our-team-form";
import MatingAdviceForm from "./js/blocks/express_form/templates/mating_advice_form/view";
import ContactForm from "./js/blocks/express_form/templates/contact_form/view";
import BookingForm from "./js/blocks/express_form/templates/booking_form/view";
import AdditionalNav from "./js/blocks/autonav/templates/additional_nav/view";
import HonourRollTable from "./js/blocks/msv_table/honour-roll";
import JsTabs from 'js-tabs';
import WbAccordion from "./js/blocks/wb_arion_api_viewer/wb-accordion";
import VimeoVideoGallery from "./js/blocks/vimeo-video-gallery";
import LotPage from "./js/layouts/lot-page";
import WbImageGallery from "./js/blocks/wb-image-gallery";
import AudioPlayer from "./js/blocks/wb-audio-player";
import WbCustomSlider from "./js/blocks/wb-custom-slider";
import NewsSearchBlock from "./js/blocks/search/templates/news-search";
import OtherSalesPage from "./js/layouts/other-sales-page";
import SalePage from "./js/layouts/sale-page";
import HomePage from "./js/layouts/home-page";
import TwcForm from "./js/blocks/express_form/templates/twc_form/view";
import CtaPopup from "./js/elements/cta-popup";

export default class App {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initialize();
        });
    }

    initialize = () => {
        this.header = new Header();
        this.autonav = new Autonav();
        new AdditionalNav();
        new SubscriptionForm();
        new TwcForm();
        new BookingForm();
        new MatingAdviceForm();
        new ContactForm();
        new SearchBlock();
        new GavelhouseListings();
        new OurTeamForm();
        new HonourRollTable();
        new WbAccordion();
        new VimeoVideoGallery();
        new SalePage();
        new LotPage();
        new WbImageGallery();
        new AudioPlayer();
        new WbCustomSlider();
        new NewsSearchBlock();
        new OtherSalesPage();
        new HomePage();
        new CtaPopup();

        let ccm = document.querySelector('.ccm-page');
        if (ccm.classList.contains('is-logged-in')) {

        }

        let tabs = document.querySelectorAll('.js-tabs-container');
        tabs.forEach((tab) => {
            let myTabs = new JsTabs({
                elm: tab,
                onClickHandlerComplete: () => {
                    window.dispatchEvent(new Event('resize'));
                },
                shouldScrollTabIntoView: false
            });
            myTabs.init();
        });
    };
}

window.App = new App();