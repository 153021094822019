export default class Header {
    constructor() {
        this.menuToggle = document.querySelector('.js-menu-toggle');
        this.bindEvents();
    }

    bindEvents = () => {
        // this.menuToggle.addEventListener('click', () => {
        //
        // });
    };

    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}
